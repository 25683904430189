<template>
  <div class="container-fluid">
    <div class="row">
      <div v-if="!isUploaded" class="col-lg-12 px-0">
        <div class="card p-half">
          <div class="card-body">
            <div class="card-title">
              Upload Orders Data
            </div>
            <div class="alert alert-danger p-1" role="alert">
              <h4 class="text-warning">
                <p-icon name="bi-exclamation-circle-fill" class="pr-1" />
                Please always use the latest template. Last update time
                25/02/2024
                <div class="badge badge-warning badge-glow mx-2">
                  ATTENTION
                </div>
              </h4>
            </div>
            <div class="row mb-1 justify-content-between mr-half">
              <div class="ml-1 border border-primary rounded">
                <span class="px-2">Choose your orders file ...</span>
                <input ref="fileInput" type="file" accept=".xlsx, .xls, .csv" hidden @change="onChange">
                <dx-util-button
                  class="py-half px-2 "
                  text="Browse"
                  type="success"
                  @click.native="$refs.fileInput.click()"
                />
              </div>
              <dx-util-button
                class="py-half px-2 "
                text="Download Orders Template"
                icon="bi bi-filetype-xlsx"
                type="success"
                @click.native="downloadExcelTemplate"
              />
            </div>
          </div>
          <div class="row px-1 mx-half">
            <dx-data-grid
              id="instructionsGrid"
              ref="instructionsGridRef"
              class="mt-1"
              :data-source="instructions"
              :allow-column-reordering="true"
              :allow-column-resizing="true"
              column-resizing-mode="widget"
              :column-auto-width="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :show-borders="true"
              :row-alternation-enabled="false"
              :hover-state-enabled="true"
            >
              <dx-paging :enabled="false" />
              <dx-column
                data-field="field_name"
                cell-template="fieldNameTemplate"
                width="auto"
              />
              <dx-column
                data-field="required"
                cell-template="requiredTemplate"
                :width="120"
                :sort-index="0"
                sort-order="desc"
              />
              <dx-column
                data-field="instructions"
                cell-template="titleCellTemplate"
              />
              <dx-column data-field="notes" cell-template="titleCellTemplate" />
              <template #titleCellTemplate="{data}">
                <span class="dx-product-title">
                  {{ data.value }}
                </span>
              </template>
              <template #fieldNameTemplate="{data}">
                <span class="font-weight-bold text-primary">
                  {{ data.value }}
                </span>
              </template>
              <template #requiredTemplate="{data}">
                <span
                  :class="
                    `text-${data.value === 'Required' ? 'danger' : 'warning'}`
                  "
                >
                  {{ data.value }}
                </span>
              </template>
            </dx-data-grid>
          </div>
        </div>
      </div>
      <div v-else class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-text d-flex justify-content-between">
              <h4>Uploaded Data</h4>
              <p-icon role="button" name="bi-x-lg" @click.native="closeCard" />
            </div>
            <div>
              <xlsx-read :file="file">
                <xlsx-json>
                  <template #default="{collection}">
                    <fileupload-list v-if="collection" :csv-data="collection" />
                  </template>
                </xlsx-json>
              </xlsx-read>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { XlsxRead, XlsxJson } from 'vue-xlsx'
import { Notify } from '@robustshell/utils/index'
import fbmService from '@/http/requests/fbm/fbmService'
import FileUploadList from './file-upload-list/FileUploadList.vue'

export default {
  components: {
    XlsxRead,
    XlsxJson,
    'fileupload-list': FileUploadList,
  },
  data() {
    return {
      file: null,
      isUploaded: false,
      saveForm: false,
      fields: ['field_name', 'required', 'instructions', 'notes'],
      instructions: [
        {
          field_name: 'Order Number',
          required: 'Required',
          instructions: 'Please enter the order number or order ID for each item.',
          notes: '',
        },
        {
          field_name: 'Order Date',
          required: 'Required',
          instructions: 'Please enter the date when the order was placed. Use the format YYYY-MM-DD.',
          notes: '',
        },
        {
          field_name: 'Item Quantity',
          required: 'Required',
          instructions: 'Please enter the quantity of the item being ordered.',
          notes: '',
        },
        {
          field_name: 'Item Product Title',
          required: 'Required',
          instructions: 'Please enter the title or name of the product being ordered.',
          notes: '',
        },
        {
          field_name: 'Item Sku',
          required: 'Required',
          instructions: 'Please enter the SKU (Stock Keeping Unit) for the product being ordered.',
          notes: '',
        },
        {
          field_name: 'Shipping Method',
          required: 'Optional',
          instructions: 'Specify the shipping method used for the order.',
          notes: '',
        },
        {
          field_name: 'Shipping Name',
          required: 'Required',
          instructions: "Please enter the recipient's name for shipping purposes.",
          notes: '',
        },
        {
          field_name: 'Shipping Address1',
          required: 'Required',
          instructions: 'Please enter the primary shipping address.',
          notes: '',
        },
        {
          field_name: 'Shipping Address2',
          required: 'Optional',
          instructions: 'Enter additional shipping address information if applicable.',
          notes: '',
        },
        {
          field_name: 'Shipping Company',
          required: 'Optional',
          instructions: 'Specify the shipping company or carrier if available.',
          notes: '',
        },
        {
          field_name: 'Shipping City',
          required: 'Required',
          instructions: 'Please enter the city for shipping purposes.',
          notes: '',
        },
        {
          field_name: 'Shipping Zip',
          required: 'Required',
          instructions: 'Please enter the ZIP or postal code for shipping purposes.',
          notes: '',
        },
        {
          field_name: 'Shipping Province Code',
          required: 'Required',
          instructions: 'Please enter the province code for shipping purposes.',
          notes: '',
        },
        {
          field_name: 'Shipping Country Code',
          required: 'Required',
          instructions: 'Please enter the country code for shipping purposes. It should be US, CA or MX',
          notes: '',
        },
        {
          field_name: 'Shipping Phone',
          required: 'Optional',
          instructions: "Enter the recipient's phone number for shipping purposes if available.",
          notes: '',
        },
        {
          field_name: 'Item Price',
          required: 'Optional',
          instructions: 'Please enter the price per unit for the item being ordered.',
          notes: '',
        },
        {
          field_name: 'Currency',
          required: 'Optional',
          instructions: 'Please specify the currency used for the order. If not provided, USD will be assumed.',
          notes: '',
        },
        {
          field_name: 'Notes',
          required: 'Optional',
          instructions: 'Enter any additional notes or information regarding the item here.',
          notes: '',
        },
      ],

    }
  },
  methods: {
    onChange(event) {
      const file = event.target.files ? event.target.files[0] : null
      const name = file.name
      const lastDot = name.lastIndexOf('.')
      const ext = name.substring(lastDot + 1)
      if (ext !== 'xlsx' && ext !== 'xls' && ext !== 'csv') {
        Notify.warning(
          'You can upload only xlsx, xls or csv files. Please always use PSH Excel Order Template.',
        )
        this.downloadExcelTemplate()
        return
      }

      this.file = file
      if (this.file != null) {
        this.isUploaded = !this.isUploaded
      }
    },
    closeCard() {
      this.isUploaded = !this.isUploaded
      this.file = null
      this.selected = []
    },
    downloadExcelTemplate() {
      fbmService.downloadTemplate().then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response], { type: 'application/csv' }))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'order_manual_import_template.csv')
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },
  },
}
</script>
