<template>
  <div>
    <div class="row">
      <div class="col-12 p-1">
        <div class="d-flex justify-content-start align-items-center">
          <dx-util-validation-group :ref="manualOrderRef">
            <div class="d-flex align-items-center">
              <div class="mr-1">
                <dx-util-select-box
                  v-model="fbmSelectedStore"
                  label="Store"
                  :data-source="stores"
                  display-expr="storeName"
                  search-mode="contains"
                  :show-clear-button="true"
                  :search-enabled="true"
                >
                  <dx-util-validator>
                    <dx-util-required-rule message="Store is required" />
                  </dx-util-validator>
                </dx-util-select-box>
              </div>
              <div class="mr-1">
                <dx-util-select-box
                  v-model="selectedWarehouse"
                  label="Warehouse"
                  :data-source="warehouseOptions"
                  display-expr="name"
                  value-expr="id"
                >
                  <dx-util-validator>
                    <dx-util-required-rule message="Warehouse is required" />
                  </dx-util-validator>
                </dx-util-select-box>
              </div>
            </div>
          </dx-util-validation-group>
          <dx-util-button text="Submit" type="success" class="px-2 my-half" @click="submitData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import shipService from '@/http/requests/ship/shipService'
import { Notify } from '@robustshell/utils/index'
import useCurrentUser from '@/libs/app/current-user'
import fbmService from '@/http/requests/fbm/fbmService'
import useFbmState from '../../../useFbmStates'

export default {
  props: {
    csvData: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { userCompanyType } = useCurrentUser()
    const { fbmStores, loadFbmStores } = useFbmState()

    return {
      userCompanyType, fbmStores, loadFbmStores,
    }
  },
  data: () => ({
    fbmSelectedStore: null,
    selectedWarehouse: null,
    warehouseOptions: [],
    manualOrderRef: 'manualOrderGroup',
  }),
  computed: {
    stores() {
      return this.fbmStores.filter(el => (el.storeId !== 0 && el.isManualImport))
    },
    validationGroup() {
      return this.$refs[this.manualOrderRef].instance
    },
  },
  watch: {
    csvData: () => {},
  },
  mounted() {
    this.getWarehouseOptions()
  },
  methods: {
    async handleSubmit() {
      if (this.form === null) {
        Notify.danger('Please upload csv or excel file.')
        return
      }
      const response = await this.validationGroup.validate()
      if (response.isValid) {
        // TODO: update it
        await fbmService.uploadManualOrders(this.selectedWarehouse, this.fbmSelectedStore.storeId, this.form)
        this.$emit('emit-upload-form-saved')
        this.closePopup()
      }
    },
    async getWarehouseOptions() {
      this.warehouseOptions.splice(0, this.warehouseOptions.length)
      await shipService.fetchWarehouses().then(result => {
        result.data.forEach(element => {
          this.warehouseOptions.push({ id: element.id, name: element.name })
        })
      })
      if (this.warehouseOptions?.length === 1) {
        this.selectedWarehouse = this.warehouseOptions[0].id
      }
    },
    async submitData() {
      const data = JSON.parse(JSON.stringify(this.csvData))
      const payload = data.map(item => {
        const newItem = {}
        Object.keys(item).forEach(key => {
          newItem[key.charAt(0).toLowerCase() + key.slice(1)] = item[key]
        })
        return newItem
      })

      const response = await this.validationGroup.validate()
      if (response.isValid) {
        await fbmService.uploadManualOrders(this.selectedWarehouse, this.fbmSelectedStore.storeId, payload)
        this.$router.push({ name: 'fbm-force' })
      }
    },
  },
}
</script>
