<template>
  <div class="row">
    <!-- FBM FORCE FILE UPLOAD LIST -->
    <div v-if="errorSummary.length > 0" class="col-md-12 mb-1">
      <dx-util-list
      :data-source="errorSummary"
      :grouped="true"
      :collapsible-groups="true"
      show-scrollbar="always"
      :hover-state-enabled="false"
      :focus-state-enabled="false"
      :element-attr="listAttributes"
      />
    </div>
    <div v-else class="col-md-12">
      <fileupolad-save v-if="csvData" :csv-data="grid.tableData" />
    </div>
    <div class="col-md-12">
      <dx-data-grid
          id="fileUploadItemsGrid"
          ref="mainGrid"
          key-expr="Order"
          :height="gridSetHeight"
          :data-source="grid.tableData"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          @row-prepared="setErrorRow"
      >
        <dx-column data-field="OrderNumber" />
        <dx-column data-field="Notes" />
        <dx-column data-field="Currency" />
        <dx-column data-field="OrderDate" />
        <dx-column data-field="ShippingMethod" />
        <dx-column data-field="ShippingName" />
        <dx-column data-field="ShippingAddress1" />
        <dx-column data-field="ShippingAddress2" />
        <dx-column data-field="ShippingCompany" />
        <dx-column data-field="ShippingCity" />
        <dx-column data-field="ShippingZip" />
        <dx-column data-field="ShippingProvinceCode" />
        <dx-column data-field="ShippingCountryCode" />
        <dx-column data-field="ShippingPhone" />
        <dx-column data-field="ItemQuantity" />
        <dx-column data-field="ItemProductTitle" />
        <dx-column data-field="ItemSku" />
        <dx-column data-field="ItemPrice" />
        <dx-paging :page-size="500" />
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
// eslint-disable-next-line no-unused-vars
import { countriesDetail, states } from '@/http/requests/statics/locations'
import GridBase from '@core/dev-extreme/mixins/grid/gridBase'
import toUpper from 'lodash/toUpper'
import {
  isNumber,
  isNumeric,
  isRequired,
  isExist,
  isValidMsku,
} from '@/views/apps/ship/validationItem.js'
import FileUploadSave from '../file-upload-save/FileUploadSave.vue'

const countries = ['US', 'CA', 'MX']

export default {
  components: {
    'fileupolad-save': FileUploadSave,
  },
  mixins: [GridBase],
  props: {
    csvData: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    grid: {
      tableData: [],
    },
    noValidData: false,
    listAttributes: {
      id: 'errorList',
    },
    errorSummary: [],
  }),
  mounted() {
    this.initData()
  },
  methods: {
    gridSetHeight() {
      return window.innerHeight / 1.4
    },
    initData() {
      this.csvData.forEach((item, index) => {
        const item1 = JSON.parse(JSON.stringify(item).replace(/"\s+|\s+"/g, '"'))
        const result = JSON.parse(JSON.stringify(this.checkDataItem(item1, index)))
        this.grid.tableData.push(result)
      })
    },
    setErrorRow(e) {
      if (e.rowType === 'data' && e.data.rowVariant === true) {
        e.rowElement.style.backgroundColor = '#ea5455'
        e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '')
      }
    },
    checkDataItem(item, index) {
      const row = this.normalizeRowData(item)
      row.Order = index + 1
      const message = []
      if (!isRequired(row.OrderNumber)) {
        row.OrderNumber = ''
        message.push('Please enter a valid order number.')
      }

      if (!isRequired(row.ItemSku) || !isValidMsku(row.ItemSku)) {
        row.ItemSku = ''
        message.push('Please enter a valid SKU.')
      }

      if (!isRequired(row.ItemProductTitle)) {
        row.ItemProductTitle = ''
        message.push('Please enter a product title.')
      }

      if (!isNumber(row.ItemQuantity)) {
        row.ItemQuantity = ''
        message.push(
          'Please enter a valid item quantity.',
        )
      }
      if (!isRequired(row.ShippingName)) {
        row.ShippingName = ''
        message.push('Please enter the recipients name.')
      }

      if (!isRequired(row.OrderDate)) {
        row.OrderDate = ''
        message.push('Please enter the order date.')
      }

      if (!this.isValidOrderDate(row)) {
        row.OrderDate = ''
        message.push('Please enter a valid date like YYYY-MM-DD (2021-03-26) in text format.')
      }

      if (!isRequired(row.ShippingAddress1)) {
        row.ShippingAddress1 = ''
        message.push('Please enter the shipping address.')
      }

      if (!isRequired(row.ShippingCity)) {
        row.ShippingCity = ''
        message.push('Please enter the shipping city.')
      }

      if (!isRequired(row.ShippingZip)) {
        row.ShippingZip = ''
        message.push('Please enter the shipping zip code.')
      }

      if (!isExist(row.ShippingCountryCode, countries)) {
        message.push(
          'Please select a valid country code. Valid country codes are US, CA and MX',
        )
      }

      if (row.ShippingCountryCode === 'US') {
        if (!isExist(row.ShippingProvinceCode, states)) {
          message.push(
            'Please select a valid state code for the US.',
          )
        }
      }
      if (message.length > 0) {
        row.rowVariant = true
        this.errorSummary.push({
          key: `Line ${index + 1}: There are ${message.length} errors in line ${index + 1}`,
          items: message,
        })
      }

      return row
    },
    normalizeRowData(item) {
      if (item.ShippingProvinceCode) item.ShippingProvinceCode = toUpper(item.ShippingProvinceCode)
      if (item.ShippingCountryCode) item.ShippingCountryCode = toUpper(item.ShippingCountryCode)
      if (item.ShippingCountryCode === 'USA') item.ShippingCountryCode = 'US'

      item.ItemPrice = String(item.ItemPrice).replace(',', '.')
      item.ItemPrice = parseFloat(item.ItemPrice.replace(/[^.0-9]/g, '')).toFixed(2)

      // eslint-disable-next-line eqeqeq
      if (item.ItemPrice === 'NaN') {
        item.ItemPrice = 0
      }

      item.OrderNumber = item.OrderNumber.toString()
      item.ShippingZip = item.ShippingZip.toString()

      return item
    },
    excelDateToJSDate(date) {
      return new Date(Math.round((date - 25569) * 86400 * 1000))
    },
    isValidOrderDate(item) {
      const date = item.OrderDate
      if (date !== '' && date !== null && typeof date !== 'undefined') {
        if (isNumeric(date)) {
          // eslint-disable-next-line no-param-reassign
          item.OrderDate = this.excelDateToJSDate(date)
          return true
        }
        const regEx = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/
        return date.match(regEx) != null
      }
      return false
    },
  },
}
</script>

<style lang="scss">
  #errorList {
    .dx-scrollable-content {
      padding-right: 0px !important;
    }
    .dx-list-group {
      background-color: rgba(255, 255, 255,1.0);
      .dx-list-group-header {
        color: #c0392b;
        padding: 10px 10px 10px !important;
        border-bottom: 1px solid rgba(164, 176, 190,1.0) !important;
        border-top: 1px solid rgba(164, 176, 190,1.0) !important;
        &::before{
          border-top-color: #c0392b;
        }
      }
      .dx-list-item{
        border-top:  1px solid rgba(164, 176, 190,1.0) !important;
        .dx-item-content {
          &.dx-list-item-content{
            color: #c0392b;
          }
        }
      }
    }
  }
</style>>
